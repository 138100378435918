import { ulid } from "ulid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { Basket, BasketItem } from "@/types";

const initialBasketState: Basket = { items: {} };

if (typeof window !== "undefined") {
	const local = localStorage.getItem("basket");

	if (local !== null) {
		const { items } = JSON.parse(local);
		initialBasketState.items = items;
	}
}

export const basketSlice = createSlice({
	name: "basket",
	initialState: initialBasketState,
	reducers: {
		setBasket: (state, action: PayloadAction<Basket>) => {
			state.items = action.payload.items;
			localStorage.setItem("basket", JSON.stringify(state));
		},
		addToBasket: (state, action: PayloadAction<BasketItem>) => {
			const id = ulid();
			state.items[id] = action.payload;
			localStorage.setItem("basket", JSON.stringify(state));
		},
		editInBasket: (state, action: PayloadAction<{ id: string; item: BasketItem }>) => {
			state.items[action.payload.id] = action.payload.item;
			localStorage.setItem("basket", JSON.stringify(state));
		},
		removeFromBasket: (state, action: PayloadAction<string>) => {
			delete state.items[action.payload];
			localStorage.setItem("basket", JSON.stringify(state));
		},
		clearBasket: (state) => {
			state.items = {};
			localStorage.removeItem("basket");
		},
	},
});

export const { setBasket, addToBasket, editInBasket, removeFromBasket, clearBasket } =
	basketSlice.actions;
export default basketSlice.reducer;
