"use client";

import Link from "next/link";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { cn } from "@/lib/utils";
import { CookieConsentData } from "@/types";
import { useDelayedToggleState } from "@/lib/hooks";
import { LOCAL_GDPR_CONSENT, DEFAULT_COOKIE_CONSENT } from "@/lib/constant";

import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

export default function CookieConcentPopup() {
	const [open, show, setOpen] = useDelayedToggleState(false, 500);
	const [showSettings, setSettingsVisibility] = useState(false);

	useEffect(() => {
		const consent = localStorage.getItem(LOCAL_GDPR_CONSENT);
		if (consent === null || !consent.startsWith("{")) setOpen(true);
	}, [setOpen]);

	const onAccept = () => {
		localStorage.setItem(LOCAL_GDPR_CONSENT, JSON.stringify(DEFAULT_COOKIE_CONSENT));
		setOpen(false);
	};

	return (
		<div
			className={cn(
				"fixed z-40 bottom-0 right-0 sm:right-4 sm:bottom-4 w-full sm:max-w-md duration-300 transition-[opacity,transform]",
				open ? "translate-y-0 opacity-100" : "translate-y-8 opacity-0",
				!show && "hidden",
			)}
		>
			<div className="m-3 rounded-md border border-secondary/10 bg-background shadow-lg">
				<div className="grid gap-2">
					<div className="flex h-14 items-center justify-between border-b border-secondary/10 p-4">
						<h1 className="text-lg font-medium">We Value Your Privacy</h1>
					</div>

					<div className="px-4 pt-2">
						<p className="mb-2 text-start text-sm font-normal">
							We use cookies to ensure the essential functions of our website and to
							enhance your online experience. You can choose to opt in or out of each
							category at any time. For more information about cookies and how we use
							them, please read our{" "}
							<Link href="/compliance/privacy" className="underline">
								Privacy Policy
							</Link>
						</p>
					</div>

					<div className="grid grid-cols-2 gap-2 border-t border-secondary/10 p-4 py-5">
						<Button onClick={onAccept} className="col-span-full" variant="default">
							ACCEPT COOKIES
						</Button>
						<PreferenceDialog
							show={[showSettings, setSettingsVisibility]}
							close={() => setOpen(false)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

type PreferenceDialogProps = {
	show: [boolean, Dispatch<SetStateAction<boolean>>];
	close: () => void;
};

function PreferenceDialog({ show: [show, setVisibility], close }: PreferenceDialogProps) {
	const [consent, setConsent] = useState<CookieConsentData>(DEFAULT_COOKIE_CONSENT);

	useEffect(() => {
		const data = localStorage.getItem(LOCAL_GDPR_CONSENT);
		if (data === null || !data.startsWith("{")) return;

		setConsent(JSON.parse(data));
	}, []);

	const onValueChange = (key: keyof CookieConsentData, value: boolean) => {
		setConsent({ ...consent, [key]: value });
	};

	const onSave = () => {
		const consentString = JSON.stringify(consent);
		localStorage.setItem(LOCAL_GDPR_CONSENT, consentString);
		setVisibility(false);
		close();
	};

	const onAcceptAll = () => {
		localStorage.setItem(LOCAL_GDPR_CONSENT, JSON.stringify(DEFAULT_COOKIE_CONSENT));
		setVisibility(false);
		close();
	};

	return (
		<Dialog open={show} onOpenChange={(x) => setVisibility(x)}>
			<DialogTrigger asChild>
				<Button className="col-span-full" variant="outline">
					MANAGE PREFERENCES
				</Button>
			</DialogTrigger>

			<DialogContent className="max-w-2xl p-0">
				<DialogTitle className="px-6 pt-6">Cookie Settings</DialogTitle>

				<div className="space-y-1 px-6 text-start text-sm font-normal">
					<p>We use a variety of our own and third-party cookies on this website:</p>
					<ul className="list-disc text-pretty pl-6 text-sm">
						<li>
							Essential cookies: which are necessary for the website&apos;s core
							functionality
						</li>
						<li>
							Performance and analytics cookies: which help us understand how users
							interact with the site and gather insights to improve performance
						</li>
						<li>
							Targeting and advertising cookies, which allow us to deliver relevant
							content and advertisements tailored to your interests.
						</li>
					</ul>
					<p className="!mt-2">
						By accepting all cookies, you consent to the use of these technologies. You
						can also choose to accept or reject specific cookie types and manage your
						preferences at any time.
					</p>
				</div>

				<ul className="flex flex-col px-6">
					<DialogSwitch
						id="cookies-required"
						label="Essential Cookies"
						disabled
						value={consent?.required ?? true}
					/>
					<DialogSwitch
						id="cookies-performance"
						label="Performance and Analytics Cookies"
						disabled={consent === undefined}
						value={consent?.performance ?? true}
						onValueChange={(x) => onValueChange("performance", x)}
					/>
					<DialogSwitch
						id="cookies-advertisements"
						label="Targeting and Advertising Cookies"
						disabled={consent === undefined}
						value={consent?.advertisements ?? true}
						onValueChange={(x) => onValueChange("advertisements", x)}
					/>
				</ul>

				<span className="px-6 text-xs opacity-80">
					For more information about cookies and how we use them, please read our{" "}
					<Link href="/compliance/privacy" className="underline">
						privacy policy.
					</Link>
				</span>

				<div className="flex items-center justify-between border-t border-secondary/10 px-6 py-4">
					<Button variant="ghost" onClick={onAcceptAll} className="text-secondary">
						ACCEPT ALL
					</Button>
					<Button onClick={onSave}>SAVE PREFERENCES</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

type DialogSwitch = {
	id: string;
	label: string;
	value: boolean;
	disabled?: boolean;
	onValueChange?: (x: boolean) => void;
};

function DialogSwitch({ id, label, value, disabled, onValueChange }: DialogSwitch) {
	return (
		<li className="inline-flex h-10 items-center justify-between text-sm2">
			<label htmlFor={id} className="select-none">
				{label}
			</label>
			<Switch id={id} checked={value} onCheckedChange={onValueChange} disabled={disabled} />
		</li>
	);
}
