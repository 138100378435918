import { usePathname, useSearchParams } from "next/navigation";
import {
	type RefObject,
	type SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
	type Dispatch,
	useSyncExternalStore,
} from "react";

export function useWindowResizeSizing(defaultValue: number, delta = 0) {
	const height = useRef(defaultValue);

	useEffect(() => {
		const calculate = () => (height.current = window.innerHeight + delta);

		calculate();
		window.addEventListener("resize", calculate);

		return () => {
			window.removeEventListener("resize", calculate);
		};
	}, [delta]);

	return height;
}

/**
 * Creates a boolean where one gets delayed than the other whenever the setter is called.
 *
 * This useful for dialogs/popups where the content is inserted to the DOM, and the visibility is animated.
 *
 * ## Setter logic:
 * 1. When the `toggled` is true: `delayed` is delayed and the `toggled` is updated in the next render.
 * 2. When the current `delayed` is true: `toggled` is delayed and the `delayed` is updated in the next render.
 */
export function useDelayedToggleState(
	defaultValue: boolean,
	delay = 300,
): [boolean, boolean, Dispatch<SetStateAction<boolean>>] {
	const [toggled, setToggle] = useState(defaultValue);
	const [delayed, setDelayed] = useState(defaultValue);
	const setState = useCallback(
		(value: boolean | ((old: boolean) => boolean)) => {
			const x = typeof value === "function" ? value.call(undefined, toggled) : value;
			if (toggled) {
				setToggle(x);
				setTimeout(() => setDelayed(x), delay);
			} else {
				setDelayed(x);
				setTimeout(() => setToggle(x), delay);
			}
		},
		[delay, toggled],
	);

	return [toggled, delayed, setState] as const;
}

export function useOutOfBoundsHitToggle<E extends Element = Element>(
	ref: RefObject<E>,
	[state, setState]: [boolean, Dispatch<SetStateAction<boolean>>],
) {
	useEffect(() => {
		const onBoundClick = (e: MouseEvent) => {
			if (!ref.current?.contains(e.target as Node)) setState(false);
		};

		if (state) document.addEventListener("click", onBoundClick);

		return () => {
			document.removeEventListener("click", onBoundClick);
		};
	}, [ref, state, setState]);
}

export function useDebounce<T>(run: (arg0: T) => void, offset: number) {
	const source = useRef<number>(NaN);

	return {
		clear: () => clearTimeout(source.current),
		run: (arg0: T) => {
			clearTimeout(source.current);
			source.current = window.setTimeout(() => run.call(undefined, arg0), offset);
		},
	};
}

export function useQueryParamsMut(removeValAtKey: Record<string, string> = {}) {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const createQueryString = useCallback(
		(name: string, value: string) => {
			const params = new URLSearchParams(searchParams);
			const removeValue = removeValAtKey[name];

			if (removeValue !== undefined && removeValue === value) params.delete(name);
			else params.set(name, value);

			return params.toString();
		},
		[removeValAtKey, searchParams],
	);

	return { pathname, searchParams, createQueryString };
}

/**
 * @param removeValAtKey A record of keys that should be removed from the query string when value is eq.
 *
 * @example
 * const { setMutSearchParams } = useQueryParamMut({ "sortBy": "default" });
 *
 * setMutSearchParams("sortBy", "createdAt:asc") // "?sortBy=createdAt:asc"
 * setMutSearchParams("sortBy", "default") // ""
 */
export function useTempQueryParamsMut(removeValAtKey: Record<string, string> = {}) {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const [mutSearchParams, setMutSearchParams] = useState<URLSearchParams>(searchParams);

	// Note(Curstantine): Synchronize useSearchParams to mutSearchParams in case the component using this hook is mounted,
	// and some other component changes the search params.
	useEffect(() => setMutSearchParams(searchParams), [searchParams]);

	const setSearchParam = useCallback(
		(name: string, value: string) => {
			const params = new URLSearchParams(mutSearchParams);
			const removeValue = removeValAtKey[name];

			if (!value || (removeValue !== undefined && removeValue === value)) params.delete(name);
			else params.set(name, value);

			setMutSearchParams(params);
		},
		[removeValAtKey, mutSearchParams],
	);

	return { pathname, mutSearchParams, setSearchParam };
}

export function usePlayerControls(target: RefObject<HTMLVideoElement>) {
	const subscribe = useCallback(
		(callback: () => void) => {
			target.current?.addEventListener("play", callback);
			target.current?.addEventListener("pause", callback);

			return () => {
				target.current?.removeEventListener("play", callback);
				target.current?.removeEventListener("pause", callback);
			};
		},
		[target],
	);

	const getSnapshot = useCallback(() => {
		const tx = target.current;
		return (tx?.paused ?? true) && tx?.seekable !== undefined;
	}, [target]);

	const paused = useSyncExternalStore(subscribe, getSnapshot, () => true);

	const toggle = useCallback(async () => {
		try {
			paused ? await target.current?.play() : target.current?.pause();
		} catch (e) {
			console.error(e);
		}
	}, [paused, target]);

	return {
		paused,
		toggle,
	};
}
