import { Api } from "@/app/api";
import type {
	Service,
	ServiceFrequentlyBought,
	ServiceReview,
	ServiceStatus,
	CreateServiceDto,
	ServicesApiResponse,
} from "@/types";

export const serviceInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		getServices: builder.query<{ docs: Service[] }, void>({
			query: () => ({
				url: "/v1/services",
			}),
			providesTags: ["services"],
		}),
		getService: builder.query<Service, string>({
			query: (id) => `/v1/services/${id}`,
			providesTags: ["services"],
		}),
		getServiceBySearch: builder.query<{ docs: Service[] }, string>({
			query: (id) => `/v1/services?${id}`,
			providesTags: ["services"],
		}),
		getCustomServicesByIds: builder.query<{ docs: Service[] }, string>({
			query: (ids) => `/v1/services?page=1&pagination=true&ids=${ids}&isCustomOffer=true`,
			providesTags: ["services"],
		}),
		getServicesByIds: builder.query<{ docs: Service[] }, string>({
			query: (ids) => `/v1/services?page=1&pagination=true&ids=${ids}`,
			providesTags: ["services"],
		}),
		getRatingByServiceId: builder.query<ServiceReview[], string>({
			query: (ids) => `/v1/services/${ids}/feedbacks`,
		}),
		createService: builder.mutation<Service, CreateServiceDto>({
			query: (body) => ({
				url: "/v1/services",
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["category"],
		}),
		editService: builder.mutation<void, { serviceId: string; body: Partial<CreateServiceDto> }>(
			{
				query: ({ serviceId, body }) => ({
					url: `/v1/services/${serviceId}`,
					method: "PATCH",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
					},
				}),
				invalidatesTags: ["services"],
			},
		),
		updateService: builder.mutation<void, { serviceId: string; body: { isPopular: boolean } }>({
			query: ({ serviceId, body }) => ({
				url: `/v1/services/${serviceId}`,
				method: "PATCH",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["services"],
		}),
		searchServices: builder.query<
			ServicesApiResponse,
			{
				search?: string;
				sortBy?: string;
				page?: number;
				limit?: number;
				categoryID?: string;
				isCustomOffer?: boolean;
				status?: ServiceStatus;
				slug?: string;
			}
		>({
			query: ({ search, sortBy, page, limit, categoryID, isCustomOffer, status, slug }) => ({
				url: `/v1/services`,
				params: {
					category: categoryID,
					page: page,
					limit: limit,
					search: search,
					sortBy: sortBy,
					isCustomOffer: isCustomOffer,
					slug,
					status,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
			providesTags: ["services"],
		}),
		getFBTServices: builder.query<ServiceFrequentlyBought, string>({
			query: (id) => `/v1/services/${id}/frequently-bought-together`,
			providesTags: ["services-frequently-bought"],
		}),
		setFBTServices: builder.mutation<void, { serviceID: string; body: string[] }>({
			query: ({ serviceID, body }) => ({
				url: `/v1/services/${serviceID}/frequently-bought-together`,
				method: "POST",
				body: JSON.stringify({
					serviceIds: body,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["services-frequently-bought"],
		}),
		updateFBTServices: builder.mutation<
			void,
			{ id: string; serviceId: string; body: string[] }
		>({
			query: ({ id, serviceId, body }) => ({
				url: `/v1/services/${serviceId}/frequently-bought-together/${id}`,
				method: "PATCH",
				body: JSON.stringify({ serviceIds: body }),
				headers: {
					"Content-Type": "application/json",
				},
			}),
			invalidatesTags: ["services-frequently-bought"],
		}),
		setFeedBack: builder.mutation<
			void,
			{
				serviceID: string;
				body: {
					orderId: string;
					subOrderId: string;
					rating: number;
					comment: string;
				};
			}
		>({
			query: ({ serviceID, body }) => ({
				url: `/v1/services/${serviceID}/feedbacks`,
				method: "POST",
				body: {
					orderId: body.orderId,
					subOrderId: body.subOrderId,
					rating: body.rating,
					comment: body.comment,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
	}),
});

export const {
	useGetServicesQuery,
	useGetServiceQuery,
	useGetServicesByIdsQuery,
	useGetRatingByServiceIdQuery,
	useSearchServicesQuery,
	useLazySearchServicesQuery,
	useCreateServiceMutation,
	useUpdateServiceMutation,
	useGetServiceBySearchQuery,
	useGetFBTServicesQuery,
	useSetFBTServicesMutation,
	useUpdateFBTServicesMutation,
	useSetFeedBackMutation,
	useEditServiceMutation,
	useGetCustomServicesByIdsQuery,
} = serviceInjection;
