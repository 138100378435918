"use client";

import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import type { Session } from "next-auth";
import { useMemo, useState } from "react";
import { ChevronDown, X } from "lucide-react";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@radix-ui/react-collapsible";

import type { Plan } from "@/types";
import { useDelayedToggleState } from "@/lib/hooks";
import { cn, getInitials, isUserAdmin, isUserPremium, toHeaderCase } from "@/lib/utils";

import AvatarIcon from "@/components/Avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";

import menuIcon from "/public/assets/home/navigation/menu.png";

type Props = { session: Session | null; plan: Plan | null };
export default function NavMobileMenuButton({ session, plan }: Props) {
	const [clicked, showMenu, setMenuVisibility] = useDelayedToggleState(false, 300);

	const show = () => {
		const root = document.body;
		root.style.overflow = "hidden";
		setMenuVisibility(true);
	};

	const hide = () => {
		const root = document.body;
		root.style.overflow = "auto";
		setMenuVisibility(false);
	};

	return (
		<div className="group">
			<button
				role="combobox"
				aria-controls="nav-left-menu"
				aria-expanded={showMenu}
				aria-label="Menu"
				className="flex size-10 items-center justify-center"
				onClick={showMenu ? hide : show}
			>
				<ButtonContent expanded={clicked} session={session} />
			</button>
			{showMenu && (
				<div
					id="notification-dropdown"
					onClick={hide}
					className={cn(
						"absolute right-0 top-[90px] w-screen bg-black/10 opacity-0 transition-opacity duration-300 flex justify-end",
						{ "opacity-100": clicked },
					)}
				>
					<MenuDrawer expanded={clicked} session={session} plan={plan} />
				</div>
			)}
		</div>
	);
}

type ButtonContentProps = { expanded: boolean; session: Session | null };
function ButtonContent({ expanded, session }: ButtonContentProps) {
	const userInitials = useMemo(
		() => getInitials(session?.user.firstName, session?.user.lastName),
		[session],
	);

	if (expanded) {
		return <X className="text-secondary" />;
	}

	if (session !== null) {
		return <AvatarIcon avatarUrl={session.user.avatar} fallback={userInitials} />;
	}

	return <Image src={menuIcon} alt="" aria-hidden className="w-7" sizes="25vw" />;
}

type MenuDrawerProps = { expanded: boolean; session: Session | null; plan: Plan | null };
function MenuDrawer({ expanded, session, plan }: MenuDrawerProps) {
	return (
		<div
			className={cn(
				"flex h-[calc(100lvh-90px)] w-64 xs:w-72 translate-x-full flex-col bg-background p-8 transition-transform duration-300 gap-8 overflow-y-auto",
				"border-r border-secondary/10",
				expanded && "translate-x-0",
			)}
			// Note(Curstantine): stop bubbling click events on this div to the parent to prevent the menu-drawer from closing
			// unless of course if it's an Link element.
			onClick={(e) => {
				const { tagName } = e.target as Element;
				if (tagName !== "A") e.stopPropagation();
			}}
		>
			<div className="inline-flex flex-col gap-6 text-lg font-semibold text-secondary">
				<Link href="/">Home</Link>
				<AuthenticatedItems session={session} />
				<Link href="/subscription/monthly">Plans</Link>
				<Link href="/blogs">Content</Link>
				<Link href="/faq">FAQs</Link>
			</div>

			<DrawerPlan session={session} plan={plan} />

			<div className="inline-flex flex-1 items-end text-xs text-grey-foreground">
				<Link href="/compliance">Terms of Service | Privacy Policy</Link>
			</div>
		</div>
	);
}

function DrawerPlan({ session, plan }: Pick<MenuDrawerProps, "session" | "plan">) {
	if (session === null || plan === null) return <></>;

	const isInFreePlan = !isUserPremium(session.user.plan.duration);
	const defaultButtonClass = "bg-accent text-primary-foreground hover:bg-accent";
	const coloredButtonClass =
		"bg-primary-foreground text-accent-foreground hover:bg-primary-foreground";

	return (
		<div className="inline-flex flex-col">
			<span className="pb-2 font-semibold">Current Plan</span>
			<Button className={isInFreePlan ? defaultButtonClass : coloredButtonClass}>
				{toHeaderCase(plan.name)}
			</Button>
			{isInFreePlan && (
				<>
					<Separator className="mx-auto my-4 w-24 bg-secondary" />
					<Button asChild className={clsx(coloredButtonClass, "h-14 flex-col")}>
						<Link href="/subscription/monthly">
							Upgrade to premium
							<span className="block text-xs font-light">
								Get 20% off on all orders
							</span>
						</Link>
					</Button>
				</>
			)}
		</div>
	);
}

function AuthenticatedItems({ session }: { session: Session | null }) {
	if (session === null) return <Link href="/login">Login</Link>;

	return (
		<CollapsibleTargets
			label="Profile"
			destinations={[
				["/profile/inbox", "Inbox"],
				["/profile/order-history", "Orders"],
				["/profile/saved", "Saved"],
				["/profile/preferences", "Preferences"],
				["/profile/settings", "Settings"],
				["/profile/affiliates", "Affiliates"],
				["/profile/logout", "Logout"],
			]}
		/>
	);
}

type CollapsibleTargetsProps = {
	label: string;
	destinations: [string, string][];
};

function CollapsibleTargets({ label, destinations }: CollapsibleTargetsProps) {
	const [expanded, setExpanded] = useState(false);

	return (
		<Collapsible open={expanded} onOpenChange={setExpanded} className="space-y-2">
			<CollapsibleTrigger asChild>
				<button className="inline-flex items-center gap-2">
					{label}
					<ChevronDown
						className={cn(
							"w-6 rotate-0 transform-gpu text-secondary transition-transform duration-300",
							expanded && "rotate-180",
						)}
					/>
				</button>
			</CollapsibleTrigger>

			<CollapsibleContent
				className={clsx(
					"grid grid-cols-1 gap-2 overflow-hidden pl-8 text-lg font-normal",
					expanded ? "animate-collapsible-down" : "animate-collapsible-up",
				)}
			>
				{destinations.map(([href, label]) => (
					<Link key={href} href={href}>
						{label}
					</Link>
				))}
			</CollapsibleContent>
		</Collapsible>
	);
}
