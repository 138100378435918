import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LOCAL_CHAT_NOTICE_KEY } from "@/lib/constant";

export interface ChatState {
	showWorkMessage: boolean;
}

export const chatSlice = createSlice({
	name: "chat",
	initialState: () => {
		if (typeof window === "undefined") return { showWorkMessage: true } as ChatState;

		const localStr = localStorage.getItem(LOCAL_CHAT_NOTICE_KEY);
		const hideWorkMessage = localStr === "false";

		return { showWorkMessage: !hideWorkMessage } as ChatState;
	},
	reducers: {
		clearChatState: (state) => {
			state.showWorkMessage = false;
			localStorage.removeItem(LOCAL_CHAT_NOTICE_KEY);
		},
		setChatNoticeVisibility: (state, action: PayloadAction<boolean>) => {
			state.showWorkMessage = action.payload;
			localStorage.setItem(LOCAL_CHAT_NOTICE_KEY, action.payload ? "true" : "false");
		},
	},
});

export const { setChatNoticeVisibility, clearChatState } = chatSlice.actions;

export default chatSlice.reducer;
