import { Api } from "@/app/api";
import { CreateNewsletterSubscription } from "@/types";

export const KeywordApiInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		subscribeToNewsletter: builder.mutation<void, CreateNewsletterSubscription>({
			query: (body) => ({
				url: "/v1/newsletter",
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
	}),
});

export const { useSubscribeToNewsletterMutation } = KeywordApiInjection;
