"use client";

import clsx from "clsx";
import Image from "next/image";
import type { MouseEventHandler } from "react";

import type { Category } from "@/types";
import { useDelayedToggleState, useWindowResizeSizing } from "@/lib/hooks";

import NavDropdownItem from "@/app/components/Navigation/components/NavDropdownItem";

import menuIcon from "/public/assets/home/navigation/menu.png";
import menuHoveredIcon from "/public/assets/home/navigation/clicked/menu.png";

type Props = { categories: Category[]; className?: string };

export default function NavServicesButton({ categories, className }: Props) {
	const dropdownMaxHeight = useWindowResizeSizing(0, -100);
	const [hovered, showDropdown, setDropdownVisibility] = useDelayedToggleState(false, 300);

	const setHoverStatus: MouseEventHandler<HTMLDivElement> = (e) => {
		const show = e.type === "mouseenter";
		const root = document.body;
		root.style.overflow = show ? "hidden" : "auto";
		root.style.paddingRight = show ? "0.25rem" : "0";

		setDropdownVisibility(show);
	};

	return (
		<div
			onMouseEnter={setHoverStatus}
			onMouseLeave={setHoverStatus}
			className={clsx("group relative", className)}
		>
			<button
				role="combobox"
				aria-controls="services-dropdown"
				aria-expanded={hovered}
				className={clsx(
					"flex h-10 items-center gap-3 rounded-[50px] bg-secondary/[0.02] px-8",
					"text-sm2 font-light text-secondary",
					"transition-colors duration-300 hover:bg-tertiary/10 group-hover:bg-tertiary/10",
				)}
			>
				<Image
					src={hovered ? menuHoveredIcon : menuIcon}
					alt=""
					aria-hidden
					className="w-5"
					sizes="25vw"
				/>
				<label>Service Categories</label>
			</button>
			{showDropdown && (
				<div
					id="services-dropdown"
					className="muted-scrollbar absolute -left-2 top-4 grid w-96 grid-cols-1 opacity-0 duration-300 group-hover:opacity-100"
				>
					{/* Note(Curstantine): To expand hit area to make the hover work within "spaces" */}
					<div aria-hidden className="h-12" />
					<ul
						role="group"
						className="inline-grid w-full overflow-y-auto border border-secondary/10 bg-background"
						style={{ maxHeight: `${dropdownMaxHeight.current}px` }}
					>
						{categories.map(({ _id, title, logo }) => (
							<NavDropdownItem
								key={_id}
								title={title}
								href={`/categories/${_id}`}
								image={logo}
							/>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}
