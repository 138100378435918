import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LOCAL_AFFILIATE_REF_KEY } from "@/lib/constant";

interface LocalAffiliateRef {
	ref: string;
	createdAt: number;
}

export interface AffiliateState {
	affiliatedBy: string | null;
}

const EXPIRY_MILLISECONDS = 604800000;

export const affiliateSlice = createSlice({
	name: "affiliate",
	initialState: () => {
		if (typeof window === "undefined") return { affiliatedBy: null } as AffiliateState;

		const localStr = localStorage.getItem(LOCAL_AFFILIATE_REF_KEY);
		const local = localStr === null ? null : (JSON.parse(localStr) as LocalAffiliateRef);

		let affiliatedBy = local?.ref ?? null;

		if (local !== null && Date.now() - local.createdAt > EXPIRY_MILLISECONDS) {
			localStorage.removeItem(LOCAL_AFFILIATE_REF_KEY);
			affiliatedBy = null;
		}

		return { affiliatedBy } as AffiliateState;
	},
	reducers: {
		clearAffiliation: (state) => {
			state.affiliatedBy = null;
			localStorage.removeItem(LOCAL_AFFILIATE_REF_KEY);
		},
		setAffiliation: (state, action: PayloadAction<string>) => {
			const localStr = localStorage.getItem(LOCAL_AFFILIATE_REF_KEY);
			const local = localStr === null ? null : (JSON.parse(localStr) as LocalAffiliateRef);

			// Ref should be set/updated if it's not saved before OR if the last saved ref is >=7 days old
			const createdAt = Date.now();
			if (local !== null && createdAt - local.createdAt < EXPIRY_MILLISECONDS) return;

			state.affiliatedBy = action.payload;
			localStorage.setItem(
				LOCAL_AFFILIATE_REF_KEY,
				JSON.stringify({ ref: action.payload, createdAt }),
			);
		},
	},
});

// Action creators are generated for each case reducer function
export const { setAffiliation, clearAffiliation } = affiliateSlice.actions;

export default affiliateSlice.reducer;
