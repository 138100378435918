import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
	token: string | null;
}

const initialState: AuthState = {
	token: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {},
});

export const {} = authSlice.actions;

export default authSlice.reducer;
