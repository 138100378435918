import Link from "next/link";
import Image, { type StaticImageData } from "next/image";

import { cn } from "@/lib/utils";

type Props = {
	title: string;
	href: string;
	image: string | StaticImageData;
	iconClassName?: string;
};
export default function NavDropdownItem({ title, href, image: logo, iconClassName }: Props) {
	return (
		<li>
			<Link
				href={href}
				className="grid h-14 grid-cols-[2rem_1fr] items-center gap-4 px-4 font-light text-foreground transition-colors hover:bg-secondary/[0.04] hover:text-secondary"
			>
				<Image
					src={logo}
					alt=""
					aria-hidden
					width={100}
					height={75}
					quality={100}
					className={cn("h-7 w-auto justify-self-center", iconClassName)}
					draggable={false}
				/>
				<span className="pointer-events-none">{title}</span>
			</Link>
		</li>
	);
}
