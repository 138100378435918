"use client";

import { FormEventHandler } from "react";

import { errorIntoString } from "@/lib/utils";
import { useSubscribeToNewsletterMutation } from "@/features/newsletter/newsLetterApiInjection";

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import LoadingSpinner from "@/components/loadingComponents/loadingSpinner";

export default function SubscriptionInput() {
	const { toast } = useToast();
	const [subscribe, { isLoading }] = useSubscribeToNewsletterMutation();

	const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		const data = new FormData(e.currentTarget);
		const email = data.get("email");

		if (email === null)
			return toast({
				variant: "destructive",
				title: "An email is required to subscribe the newsletter",
			});

		const resp = await subscribe({ email: email.toString() });
		if ("error" in resp) {
			const ex = errorIntoString(resp.error);
			return toast({
				variant: "destructive",
				title: "Failed to subscribe to the newsletter",
				description: ex,
			});
		}

		toast({ title: "Successfully subscribed to the newsletter" });
	};

	return (
		<form onSubmit={onSubmit} className="flex items-center gap-2 rounded-lg bg-accent/5 p-2">
			<Input
				type="email"
				name="email"
				placeholder="Email"
				required
				className="border-0 bg-transparent text-xsm2 focus-visible:outline-none"
			/>
			<Button
				type="submit"
				variant="ghost"
				disabled={isLoading}
				className="!text-xsm2 text-accent-foreground hover:bg-transparent hover:text-secondary"
			>
				{isLoading ? <LoadingSpinner /> : "Send"}
			</Button>
		</form>
	);
}
