import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import counterReducer from "@/features/counterSlice";
import authReducer from "@/features/auth/authSlice";
import basketReducer from "@/features/basket/basketSlice";
import affiliateReducer from "@/features/affiliate/affiliateSlice";
import chatReducer from "@/features/chat/chatSlice";
import { Api } from "./api";

export const store = configureStore({
	reducer: {
		[Api.reducerPath]: Api.reducer,
		counter: counterReducer,
		auth: authReducer,
		basket: basketReducer,
		affiliate: affiliateReducer,
		chat: chatReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(Api.middleware),
	devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
